<template>
	<main id="container" class="page-container information bg-wave3">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="Event Guide" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="page-sub-header">
					<h3 class="page-sub-title">2024 September K-Shopping <span class="prima">Festa Event Guide</span></h3>
				</div>
				<div class="information-header">
					<p class="info-header-text">To stimulate domestic demand by hosting the Korea Accompanying Festival, a nationwide large-scale consumption festival, aiming to alleviate consumer burdens and increase sales for small and medium-sized enterprises (SMEs) and small business owners. <br />A relay "consumption promotion event" held three times a year (May for Family Month, September for Chuseok, and December for Christmas)</p>
				</div>
				<div class="information-content">
					<section class="information-content-section flex">
						<div class="information-content-section__header">
							<h4 class="info-content-title">Scale of Participation</h4>
							<!-- <p class="info-content-text"> -->
							<ul class="dot-list info-content-text">
								<li><strong>Local events</strong> : 100 promotional events for SME and small business products linked with local festivals and traditional markets</li>
								<li><strong>Sales channel</strong> : Over 270 online and offline distribution channels, 1,853 traditional markets and shopping districts, and 2,325 century-old shops</li>
								<li><strong>Participating companies</strong> : Approximately 23,000 SMEs and small businesses</li>
							</ul>
							<!-- </p> -->
						</div>
						<div class="info-content-img">
							<img src="@/assets/img/information/info-img01.jpg" alt="" />
						</div>
					</section>
					<section class="information-content-section">
						<h4 class="info-content-title">Main Contents</h4>
						<article class="information-content-article">
							<h5 class="information-content-article__title"><strong>Interdepartmental Collaboration</strong> : Discount promotions for agricultural, livestock, and marine products, promotion of travel and tourism, discount events by distribution and manufacturing industries, and discount sales of excellent procurement products in conjunction with the Accompanying Festival</h5>
							<ul class="information-content-article__list">
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img11.jpg" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img12.jpg" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img13.jpg" alt="" />
									</div>
								</li>
							</ul>
						</article>
						<article class="information-content-article">
							<h5 class="information-content-article__title"><strong>Regional Collaboration</strong> : Promotional events linked with local festivals and events, discounts and promotions for products from traditional markets and shopping districts (1,812 places), century-old shops, good price businesses, and local enterprises</h5>
							<ul class="information-content-article__list">
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img21.jpg" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img22.jpg" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img23.jpg" alt="" />
									</div>
								</li>
							</ul>
						</article>
						<article class="information-content-article">
							<h5 class="information-content-article__title"><strong>Private Sector Collaboration</strong> : Sales events by distribution companies such as supermarkets and department stores, participation in discount events through domestic online shopping malls, O2O platforms, home shopping, and T-commerce</h5>
							<ul class="information-content-article__list">
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img31.png" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img32.png" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img33.png" alt="" />
									</div>
								</li>
							</ul>
						</article>
						<article class="information-content-article">
							<h5 class="information-content-article__title"><strong>International Collaboration</strong> : Collaboration with global distribution platforms, hosting Accompanying Festival themed exhibitions and discount events targeting Southeast Asia to open export opportunities for SMEs through the global Accompanying Festival</h5>
							<ul class="information-content-article__list">
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img41.png" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img42.png" alt="" />
									</div>
								</li>
								<li>
									<div class="info-content-img">
										<img src="@/assets/img/information/info_img43.png" alt="" />
									</div>
								</li>
							</ul>
						</article>
					</section>
				</div>
				<div class="information-period">
					<div class="period-desc">
						<span class="period-desc-title">Event Period</span>
						<span class="period-desc-date">2024.8.28.(Wed) ~ 9.28.(Sat), 32 days</span>
					</div>
					<div class="period-icon">
						<img src="@/assets/img/information/info-calendar-icon.png" alt="" />
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
export default {
	name: 'EventGuide',
	components: { PageHeader },
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>
